import { Box } from 'reakit'
import { DialogDisclosure, useDialogState } from 'reakit/Dialog'
import styled from 'styled-components'

import SearchBar from './SearchBar'

import Icon from 'components/Icon'
import Modal from 'components/Modal'

const SearchButtonModal = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
    const searchDialog = useDialogState()

    const handleClick = () => {
        searchDialog.setVisible(true)
    }

    const _handleClickOutside = () => {
        searchDialog.setVisible(false)
    }

    return (
        <>
            <SearchButton onClick={handleClick} aria-label="Open Search">
                <Icon icon="RiSearchLine" />
            </SearchButton>

            <Modal
                baseId={searchDialog.baseId}
                visible={searchDialog.visible}
                hideOnClickOutside={true}
                ariaLabel="Search"
            >
                <SearchModal>
                    <SearchBar
                        isAuthenticated={isAuthenticated}
                        handleClickOutside={_handleClickOutside}
                        isModal
                    />
                </SearchModal>
            </Modal>
        </>
    )
}

const SearchButton = styled(DialogDisclosure)`
    border: none;
    box-shadow: none;
    cursor: pointer;
    background: none;
    padding: 0px;

    svg {
        color: ${({ theme }) => theme.palette.neutral[999]};
    }

    &:hover svg,
    &:focus svg {
        color: ${({ theme }) => theme.palette.accent[500]};
    }
`

const SearchModal = styled(Box)`
    max-width: 800px;
    margin: auto;
    padding: 5rem;
`

export default SearchButtonModal
