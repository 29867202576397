import React from 'react'

import styled from 'styled-components'

import type { IconKeys, IconProps } from 'components/Icon'
import Icon from 'components/Icon'
import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import type { ThemedComponent } from 'theme'
import { radii } from 'theme'

export type NavigationIconProps = {
    title: string
    icon: IconKeys
    isSelected?: boolean
}

type _IconProps = IconProps & { isSelected: boolean }

const NavigationIcon = ({ title, icon, isSelected = false }: NavigationIconProps) => {
    return (
        <IconWrapper borderRadius={radii.default}>
            <StyledIcon size="default" title={title} icon={icon} isSelected={isSelected} />
        </IconWrapper>
    )
}
type StyledIconProps = ThemedComponent<_IconProps>
type StyledBoxProps = ThemedComponent<BoxProps>

const IconWrapper = styled(Box)<StyledBoxProps>`
    display: flex;
    color: ${({ theme }) => theme.palette.neutral[0]};
    padding: 2px;
    width: min-content;
    align-items: center;
    justify-items: center;
`

const StyledIcon = styled(Icon)<StyledIconProps>`
    svg {
        color: ${({ isSelected, theme }) =>
            isSelected ? theme.palette.accent[500] : theme.palette.neutral[999]};
    }
`

export default NavigationIcon
