import styled, { css } from 'styled-components'

import type { ButtonBaseProps } from 'components/Button/ButtonBase'
import { LegacyLink as Link } from 'components/Link'
import { routeMap } from 'shared/routes'
import type { ThemedComponent } from 'theme'

type ViewAllResultsButtonProps = Pick<ButtonBaseProps, 'children' | 'url'>

const ViewAllResultsButton = ({ children, url = routeMap.search }: ViewAllResultsButtonProps) => {
    return (
        <StyledButton url={url} data-cy="search-view-all-results-button">
            {children}
        </StyledButton>
    )
}

type StyledProps = ThemedComponent<ViewAllResultsButtonProps>
const StyledButton = styled(Link)<StyledProps>`
    position: sticky;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0.75rem;
    width: 100%;
    ${({ theme }) => {
        return css`
            background-color: ${theme.palette.neutral[100]};
            color: ${theme.palette.neutral[999]};
            font-weight: ${theme.fontWeights.normal};
            &:hover {
                background-color: ${theme.palette.neutral[200]};
                text-decoration: none;
            }

            &:active {
                background-color: ${theme.palette.primary[200]};
            }
        `
    }}
`

export default ViewAllResultsButton
