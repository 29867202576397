import type { ReactNode } from 'react'
import React from 'react'

import styled from 'styled-components'

import AspectRatioBox from 'components/AspectRatioBox'
import Heading from 'components/Heading'
import Hide from 'components/Hide'
import type { ImageProps } from 'components/Image'
import Image from 'components/Image'
import type { LinkProps } from 'components/Link'
import { LegacyLink as Link } from 'components/Link'
import Box from 'components/Primitives/Box'
import { ImageRatios } from 'consts/index'
import finimizeDarkIcon from 'public/images/finimize-dark-icon.png'
import { fonts } from 'theme'

export type Props = {
    imageSrc: ImageProps['src']
    imageAlt: ImageProps['alt']
    title: ReactNode
    subtitle: ReactNode
    url: LinkProps['url']
    id: string
}

const ListRow = ({ imageAlt, imageSrc, title, subtitle, url, id }: Props) => {
    return (
        <Container url={url} data-cy={`${id}-search-result`}>
            <Hide on={[true, false]}>
                <AspectRatioBox ratio={ImageRatios.SQUARE} width={60} height={60}>
                    <Image
                        src={imageSrc}
                        alt={imageAlt}
                        fallbackSrc={finimizeDarkIcon}
                        borderRadius="medium"
                        layout="fill"
                        objectFit="cover"
                    />
                </AspectRatioBox>
            </Hide>
            <Box flex={1}>
                <Heading as="h2" fontSize={fonts.size.S} marginBottom="0.5rem" maxLines={2}>
                    {title}
                </Heading>
                <Heading
                    as="h3"
                    fontSize={fonts.size.XS}
                    color="neutral.400"
                    maxLines={2}
                    fontWeight={fonts.weight.normal}
                >
                    {subtitle}
                </Heading>
            </Box>
        </Container>
    )
}

const Container = styled(Link)`
    padding: 1rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
    background: ${({ theme }) => theme.palette.neutral[0]};
    &:hover,
    :focus {
        background: ${({ theme }) => theme.palette.neutral[50]};
        text-decoration: none;
    }
    &:active {
        background: ${({ theme }) => theme.palette.neutral[200]};
    }
    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.palette.neutral[200]};
    }
`

export default ListRow
