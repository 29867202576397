import type { ReactElement } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import debounce from 'lodash/debounce'
import type { SearchBoxProps } from 'react-instantsearch-hooks-web'
import { InstantSearch, useSearchBox, useInstantSearch } from 'react-instantsearch-hooks-web'
import { useRelayEnvironment } from 'react-relay'
import styled from 'styled-components'

import SearchBox from './BaseComponents/SearchBox'
import SearchResults from './SearchResults'
import { useSearch } from './useSearch'

import Box from 'components/Primitives/Box'
import { getFBTrackingParams } from 'lib/analytics/useTrackingParams'
import { trackSearch } from 'lib/analyticsApi'

enum AlgoliaStates {
    stalled = 'stalled',
    loading = 'loading',
}

type Props = {
    isAuthenticated: boolean
    isModal?: boolean
    handleClickOutside?: () => void
}

const SearchBar = ({
    isAuthenticated,
    isModal,
    handleClickOutside: _handleClickOutside,
}: Props): ReactElement => {
    const [query, setQuery] = useState<string>('')
    const [focus, setFocus] = useState<boolean>(false)
    const { clear } = useSearchBox()
    const { status: algoliaSearchStatus } = useInstantSearch()
    const environment = useRelayEnvironment()
    const { fbclid, fbp } = getFBTrackingParams()

    const searchBoxRef = useRef<HTMLDivElement | null>(null)

    /**
     *  Indicates the local loading state.
     *  When this is set to false, it implies the user has finished their query and the query has been sent to algolia.
     *  This is not representative of the algolia search state.
     */
    const [isUserTyping, setIsUserTyping] = useState(false)

    /**
     *  Raw search function without a debounce.
     */
    const makeSearch = useCallback(
        (newQuery: string, search: (value: string) => void) => {
            search(newQuery)
            trackSearch(environment, isAuthenticated, fbclid, fbp, newQuery)
            setIsUserTyping(false)
        },
        [environment, fbclid, fbp, isAuthenticated],
    )

    /**
     * Debounced search function, should be used for all searches except clearing the query.
     * The reason behind using useMemo: https://kyleshevlin.com/debounce-and-throttle-callbacks-with-react-hooks
     */
    const debouncedMakeSearch = useMemo(() => debounce(makeSearch, 500), [makeSearch])

    /**
     * Custom query hook for the algolia SearchBox component.
     * It is important that the local query state is updated every time.
     * When the user is typing in the search box we debounce the search to prevent unnecessary api calls.
     */
    const queryHook: SearchBoxProps['queryHook'] = useCallback(
        (newQuery: string, search: (value: string) => void) => {
            setIsUserTyping(true)

            // Call the debounced search function to perform the search
            debouncedMakeSearch(newQuery, search)
            setQuery(newQuery)
        },

        [debouncedMakeSearch],
    )

    /**
     * If we navigate before the search has completed,
     * we need to cancel the debounced search to prevent a memory leak.
     */
    useEffect(() => {
        return () => {
            debouncedMakeSearch.cancel()
        }
    }, [debouncedMakeSearch])

    /**
     * Close the search results when the user clicks outside the search bar.
     */
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            // if click is outside of the search box, close the search results
            setFocus(!!searchBoxRef.current?.contains(event.target as Node))

            // if click is outside of the search box, send callback to parent (used to close search modal)
            _handleClickOutside &&
                !searchBoxRef.current?.contains(event.target as Node) &&
                _handleClickOutside()
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [query])

    useEffect(() => {
        if (!!isModal) {
            setFocus(true)
        }
    }, [isModal])

    const isSearching =
        isUserTyping ||
        algoliaSearchStatus === AlgoliaStates.loading ||
        algoliaSearchStatus === AlgoliaStates.stalled

    return (
        <Container ref={searchBoxRef} data-cy="search-bar">
            <SearchBox
                queryHook={queryHook}
                placeholder="Search Finimize"
                hasText={!!query}
                isModal={isModal}
            />

            <SearchResults
                resetSearch={clear}
                isLoading={isSearching}
                showResults={!!query && focus}
            />
        </Container>
    )
}

const Container = styled(Box)`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
`

const SearchBarWrapper = ({
    isAuthenticated,
    isModal,
    handleClickOutside: _handleClickOutside,
}: Props): ReactElement => {
    const { searchClient, indexName } = useSearch()

    return (
        <InstantSearch searchClient={searchClient} indexName={indexName}>
            <SearchBar
                isAuthenticated={isAuthenticated}
                isModal={isModal}
                handleClickOutside={_handleClickOutside}
            ></SearchBar>
        </InstantSearch>
    )
}

export default SearchBarWrapper
