import React from 'react'

import { css } from 'styled-components'

import type { BoxProps } from 'components/Primitives/Box';
import Box from 'components/Primitives/Box'
import type { ImageRatios } from 'consts/index'

type ReverseMap<T> = T[keyof T]
export type ImageRatio = ReverseMap<typeof ImageRatios>

type AspectRatioBoxProps = {
    children: React.ReactElement<any>
    ratio: ImageRatio
} & BoxProps

const childProps = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
}

const parentProps = {
    position: 'relative',
}

const AspectRatioBox = ({ children, ratio, ...props }: AspectRatioBoxProps) => {
    const child = React.Children.only(children)

    const parentStyles = css`
        &:before {
            position: relative;
            height: 0;
            content: '';
            display: block;
            padding-bottom: ${(1 / ratio) * 100 + '%'};
        }
    `

    return (
        <Box {...props} css={parentStyles} {...parentProps}>
            {React.cloneElement(child, childProps)}
        </Box>
    )
}

export default AspectRatioBox
