import type { UseHitsProps } from 'react-instantsearch-hooks-web'
import { useHits, useInstantSearch } from 'react-instantsearch-hooks-web'
import styled from 'styled-components'

import { ErrorFallback } from './ErrorFallback'
import { NoResults } from './NoResults'
import { NoResultsBoundary } from './NoResultsBoundary'
import SearchResultListRow from './SearchResultListRow'
import type { ContentPieceSearchResult } from './types'
import ViewAllResultsButton from './ViewAllResultsButton'

import Heading from 'components/Heading'
import type { BoxPropsWithTheme } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import type { DefaultStyledProps } from 'components/styled'
import { routeMap } from 'shared/routes'
import { fonts, radii, zIndex } from 'theme'

type Props = {
    resetSearch: () => void
    isLoading: boolean
    showResults: boolean
} & UseHitsProps<ContentPieceSearchResult>

const SearchResults = ({ resetSearch, showResults, isLoading, ...props }: Props) => {
    const { hits } = useHits(props)
    const { indexUiState } = useInstantSearch()

    const query = encodeURIComponent(indexUiState?.query || '')

    const searchUrl = `${routeMap.search}?q=${query}`

    if (!showResults) return null

    if (isLoading)
        return (
            <StyledDropdown>
                <LoadingContainer>
                    <LoadingText as="p">Searching...</LoadingText>
                </LoadingContainer>
            </StyledDropdown>
        )

    return (
        <StyledDropdown>
            <ScrollContainer>
                <Box padding="1rem">
                    <ResultsHeader as="p">MOST RELEVANT RESULTS</ResultsHeader>

                    <ErrorFallback />

                    <NoResultsBoundary fallback={<NoResults />}>
                        <>
                            {hits.map(hit => (
                                <div onClick={resetSearch} key={hit.objectID}>
                                    <SearchResultListRow hit={hit} />
                                </div>
                            ))}
                        </>
                    </NoResultsBoundary>
                </Box>
            </ScrollContainer>
            <ViewAllResultsButton url={searchUrl}>View all results -&gt;</ViewAllResultsButton>
        </StyledDropdown>
    )
}

const StyledDropdown = styled(Box)<BoxPropsWithTheme>`
    position: absolute;

    z-index: ${zIndex.layer04};
    max-height: 50vh;
    width: 100%;
    top: 4rem;

    display: flex;
    flex-direction: column;

    background-color: ${({ theme }: BoxPropsWithTheme) => theme.palette.neutral[0]};
    border: 1px solid;
    border-color: ${({ theme }: BoxPropsWithTheme) => theme.palette.neutral[200]};
    border-radius: ${radii.large};
    overflow: hidden;

    .ais-Highlight-highlighted {
        background-color: ${({ theme }: BoxPropsWithTheme) => theme.palette.primary[100]};
    }
`

const ScrollContainer = styled(Box)`
    overflow-y: auto;
`

const ResultsHeader = styled(Heading)<DefaultStyledProps>`
    color: ${({ theme }) => theme.palette.neutral[400]};
    font-size: ${fonts.size.XS};
    font-weight: ${fonts.weight.bold};

    padding-bottom: 1rem;
`

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6rem;
`

const LoadingText = styled(Heading)<DefaultStyledProps>`
    font-size: ${fonts.size.M};
    color: ${({ theme }) => theme.palette.neutral[600]};
    font-weight: ${fonts.weight.normal};
`

export default SearchResults
