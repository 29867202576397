import type { SearchClient } from 'algoliasearch';
import algoliasearch from 'algoliasearch'

const ALGOLIA_TOKEN = process.env.NEXT_PUBLIC_ALGOLIA_TOKEN
const ALGOLIA_APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID

if (!ALGOLIA_TOKEN || !ALGOLIA_APP_ID) {
    throw new Error(`Algolia environment variables not set correctly.`)
}

const algoliaClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_TOKEN)
const indexName = 'ContentPiece'

// This is following the example in the algolia docs:
// https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react-hooks/
const searchIgnoreEmpty: SearchClient['search'] = requests => {
    // If no query, do not make the search request and return a fake empty response
    if (requests.every(request => request.params && !request.params.query)) {
        return Promise.resolve({
            results: requests.map(() => ({
                hits: [],
                nbHits: 0,
                nbPages: 0,
                page: 0,
                processingTimeMS: 0,
                hitsPerPage: 0,
                exhaustiveNbHits: false,
                query: '',
                params: '',
            })),
        })
    }
    // Otherwise, return the search request as normal
    return algoliaClient.search(requests)
}

const searchClient = {
    ...algoliaClient,
    search: searchIgnoreEmpty,
}

export const useSearch = (): { searchClient: SearchClient; indexName: string } => {
    return { searchClient, indexName }
}
