import { useInstantSearch } from 'react-instantsearch-hooks-web'

import Heading from 'components/Heading'
import Box from 'components/Primitives/Box'
import { fonts } from 'theme'

export const NoResults = () => {
    const { indexUiState } = useInstantSearch()

    return (
        <Box>
            <Heading as="p" fontWeight={fonts.weight.normal}>
                Sorry, there are no results for <q>{indexUiState.query}</q>. Try using different
                search terms.
            </Heading>
        </Box>
    )
}
