import { useState, useEffect } from 'react'

import type { InstantSearch } from 'instantsearch.js'
import { useInstantSearch } from 'react-instantsearch-hooks-web'

import Heading from 'components/Heading'
import Box from 'components/Primitives/Box'
import { fonts } from 'theme'

export const ErrorFallback = () => {
    const { use } = useInstantSearch()
    const [error, setError] = useState<Error | null>(null)

    useEffect(() => {
        const middleware = ({
            instantSearchInstance,
        }: {
            instantSearchInstance: InstantSearch
        }) => {
            const handleError = (searchError: Error) => {
                setError(searchError)
            }

            return {
                subscribe() {
                    instantSearchInstance.addListener('error', handleError)
                },
                unsubscribe() {
                    instantSearchInstance.removeListener('error', handleError)
                },
            }
        }
        // @ts-ignore
        return use(middleware)
    }, [use])

    if (!error) {
        return null
    }

    return (
        <Box>
            <Heading as="p" fontWeight={fonts.weight.normal}>
                Sorry, we're having trouble showing search results right now. Please try again
                later.
            </Heading>
        </Box>
    )
}
