import React from 'react'

import styled from 'styled-components'

import type { IconKeys } from 'components/Icon'
import Icon from 'components/Icon'
import type { LinkProps } from 'components/Link'
import { LegacyLink as Link } from 'components/Link'
import { isExternalLink } from 'components/Link/Link'
import NavigationIcon from 'components/NavigationIcon'
import Box from 'components/Primitives/Box'
import type { BoxProps } from 'components/Primitives/Box'
import type { ThemedComponent } from 'theme'
import { radii } from 'theme'

export type NavigationItemProps = {
    name: string
    icon?: IconKeys
    url: string
    isSelected?: boolean
    /**
     * Used to provide a side effect in addition to navigating to the url provided.
     */
    onClick?: () => void
    params?: { [key: string]: string }
}

type LocalItemProps = {
    isSelected: boolean
}
export type ItemProps = BoxProps & LocalItemProps

const NavigationItem = ({
    name,
    icon,
    isSelected = false,
    url,
    onClick,
    params,
}: NavigationItemProps) => {
    return (
        <StyledLink
            url={{ pathname: url, query: params }}
            onClick={onClick}
            data-cy={`${name}-nav-item`}
        >
            <Item py={1} isSelected={isSelected}>
                {icon && <NavigationIcon title={name} icon={icon} isSelected={isSelected} />}
                <Box display="flex" width="100%" justifyContent="center" alignItems="center">
                    <TitleContainer>
                        <Title isSelected={isSelected} paddingLeft="0.25rem">
                            {name}
                        </Title>
                    </TitleContainer>
                    {isExternalLink(url) && (
                        <Icon
                            className="share-icon"
                            icon="RiShareBoxLine"
                            size="default"
                            padding="0px"
                        />
                    )}
                </Box>
            </Item>
        </StyledLink>
    )
}

// Experimental way to get typing for theme
type StyledItemProps = ThemedComponent<ItemProps>
type StyledLinkProps = ThemedComponent<LinkProps>

const StyledLink = styled(Link)<StyledLinkProps>`
    width: 100%;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    :active,
    :focus {
        text-decoration: none;
        color: ${({ theme }) => theme.palette.accent[500]};
    }
    & :visited {
        text-decoration: none;
        color: ${({ theme }) => theme.palette.neutral[999]};
    }
`

const Item = styled(Box)<StyledItemProps>`
    border-radius: ${radii.default};
    display: flex;
    transition: all ease 0.16s;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    width: 1;
    color: ${({ theme }) => theme.palette.neutral[900]};

    color: ${({ isSelected, theme }) =>
        isSelected ? theme.palette.accent[500] : theme.palette.neutral[900]};

    .share-icon {
        display: none;
    }

    &:hover,
    :active,
    &:active svg,
    &:hover svg {
        text-decoration: none;
        color: ${({ theme }) => theme.palette.accent[500]};
    }
`

const Title = styled(Box)<StyledItemProps>`
    font-size: ${({ theme }) => theme.fontSize.S};
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    margin: 0;
`

const TitleContainer = styled(Box)`
    display: flex;
    align-items: center;
`

export default NavigationItem
