import React from 'react'

import type { IconKeys } from 'components/Icon'
import { LegacyLink as Link } from 'components/Link'
import { routeMap } from 'shared/routes'
import { fonts } from 'theme'

type NavBarHeaderInputType = { name: string; url: string; icon?: IconKeys; pillText?: string }
export type NavBarLinkProps = { onClick?: () => void; params?: { [key: string]: string } }

export const HOME_LOGO_TRACKING_NAME = 'home logo'

const HUB_DIRECTORY = 'Hubs'

const navBarHeadersFull: NavBarHeaderInputType[] = [
    { name: HUB_DIRECTORY, url: routeMap.hubshub },
    { name: 'Analysis', url: routeMap.analysis, pillText: 'Premium' },
    { name: 'News', url: routeMap.news },
    { name: 'Education', url: routeMap.education },
    { name: 'Markets', url: routeMap.markets },
    { name: 'Events', url: routeMap.events },
    { name: 'For Business', url: routeMap.business },
    { name: 'Bookmarks', url: routeMap.bookmarks },
]

const profile: NavBarHeaderInputType = {
    name: 'Profile',
    url: routeMap.profile,
    icon: 'RiUserLine',
}

const navBarHeadersNotB2B: NavBarHeaderInputType[] = navBarHeadersFull.filter(
    header => header.name !== HUB_DIRECTORY,
)

type Props = {
    isB2BUser?: boolean
    showProfile?: boolean
}

export const getNavBarItems = ({ isB2BUser = false, showProfile = false }: Props) => {
    let items = navBarHeadersNotB2B

    if (isB2BUser) {
        // Filter out nav items that should only be visible to B2B users
        items = navBarHeadersFull
    }

    if (showProfile) {
        return items.concat([profile])
    }

    return items
}

export const NavBarLink = ({
    name,
    url,
    params,
    onClick,
}: NavBarLinkProps & Omit<NavBarHeaderInputType, 'icon'>) => {
    return (
        <Link
            fontWeight="bold"
            color="neutral.999"
            url={{ pathname: url, query: params }}
            fontSize={fonts.size.XS}
            onClick={onClick}
        >
            {name}
        </Link>
    )
}
