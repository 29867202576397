import React from 'react'

import styled from 'styled-components'
import { system } from 'styled-system'

import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'

type LocalHideProps = {
    on: Array<boolean>
    children: React.ReactNode
}

export type HideProps = LocalHideProps & BoxProps

const Hide = ({ children, on, ...rest }: HideProps) => {
    return (
        <_Box {...{ on }} {...rest}>
            {children}
        </_Box>
    )
}

export default Hide

const _Box = styled(Box)<HideProps>(
    system({
        on: {
            property: 'display',
            transform: (hide: boolean) => (hide ? 'none' : 'initial'),
        },
    }),
    system({
        on: {
            property: 'visibility',
            transform: (hide: boolean) => (hide ? 'hidden' : 'initial'),
        },
    }),
)
