import { type CSSProperties, type ReactElement } from 'react'

import { Item, List, Root, Trigger, Viewport } from '@radix-ui/react-navigation-menu'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import Image from 'components/Image'
import { LinkWrapper } from 'components/Link/LinkWrapper'
import NavigationIcon from 'components/NavigationIcon'
import NavigationItem from 'components/NavigationItem'
import Box from 'components/Primitives/Box'
import { trackNavigationItemClicked } from 'lib/analyticsApi'
import { getNavBarItems, HOME_LOGO_TRACKING_NAME } from 'modules/Navigation/NavBarLinks'
import finimizeBrandName from 'public/images/finimize-brand-name.svg'
import finimizeLogoPrimary from 'public/images/finimize-primary-logo.svg'
import { routeMap } from 'shared/routes'
import { zIndex } from 'theme/index'

const isSelected = (url: string, asPath: string): boolean => {
    return url === asPath.split('?')[0]
}

const NavigationItems = ({ isB2BUser }: { isB2BUser: boolean }) => {
    const { asPath } = useRouter()
    const NavBarZIndex = zIndex.layer08

    const trackHomeLogoClicked = () => trackNavigationItemClicked(HOME_LOGO_TRACKING_NAME)

    return (
        <NavigationRoot
            orientation="horizontal"
            style={{
                position: 'relative',
                overflow: 'visible',
                width: '100%',
            }}
        >
            <Box
                style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: NavBarZIndex,
                    width: '100%',
                    overflow: 'visible',
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Header pr={2}>
                        <NavItem>
                            <LinkWrapper
                                url={routeMap.home}
                                dataCy="Home-nav-item"
                                onClick={trackHomeLogoClicked}
                            >
                                <Box display="flex">
                                    <Image
                                        src={finimizeLogoPrimary}
                                        maxWidth="30px"
                                        alt="Finimize logo icon"
                                        pt="0.5rem"
                                        alignSelf="center"
                                        priority={true}
                                    />
                                    <Image
                                        src={finimizeBrandName}
                                        maxWidth="100px"
                                        alt="Finimize brand name"
                                        width="35vw"
                                        ml="0.5rem"
                                        pt="0.5rem"
                                        alignSelf="center"
                                        priority={true}
                                    />
                                </Box>
                            </LinkWrapper>
                        </NavItem>
                    </Header>

                    <Box flex={1}>
                        <List
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                listStyle: 'none',
                                padding: '0 0.5rem',
                            }}
                        >
                            {getNavBarItems({ isB2BUser }).map(header => {
                                const trackClick = () => trackNavigationItemClicked(header.name)

                                // This is hacky for the time being to make more room in the nav by
                                // just returning an icon for bookmarks on the top nav bar
                                if (header.name === 'Bookmarks') {
                                    return (
                                        <NavItem key={header.name}>
                                            <LinkWrapper
                                                url={header.url}
                                                dataCy={`${header.name}-nav-item`}
                                                onClick={trackClick}
                                            >
                                                <NavigationIcon
                                                    icon="RiBookmarkLine"
                                                    title={header.name}
                                                    isSelected={isSelected(header.url, asPath)}
                                                />
                                            </LinkWrapper>
                                        </NavItem>
                                    )
                                }
                                return (
                                    <NavItem key={header.name}>
                                        <NavigationItem
                                            name={header.name}
                                            icon={header.icon}
                                            url={header.url}
                                            isSelected={isSelected(header.url, asPath)}
                                            onClick={trackClick}
                                        />
                                    </NavItem>
                                )
                            })}
                        </List>
                    </Box>
                </Box>
            </Box>

            <Viewport style={{ position: 'relative' }} />
        </NavigationRoot>
    )
}

const NavItem = ({ children, style }: { children: ReactElement; style?: CSSProperties }) => {
    return (
        <Item>
            <Trigger
                style={{
                    background: 'none',
                    border: 'none',

                    ...style,
                }}
            >
                {children}
            </Trigger>
        </Item>
    )
}

const NavigationRoot = styled(Root)`
    height: 100%;
    width: 100%;

    * {
        // remove list stylings (e.g. bullet points) which come with react-navigation-menu
        list-style-type: none;
    }
`

const Header = styled(Box)`
    padding-top: 0.7rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    width: max-content;
`

export default NavigationItems
