import type { SearchBoxProps } from 'react-instantsearch-hooks-web'
import { SearchBox as AlgoliaSearchBox } from 'react-instantsearch-hooks-web'
import styled, { css } from 'styled-components'

import { Spinner } from 'components/Spinner'
import { fonts, palette, radii, zIndex } from 'theme'

type StyledSearchBoxProps = {
    hasText: boolean
    queryHook: SearchBoxProps['queryHook']
    placeholder: string
    isModal?: boolean
}

const SearchBox = ({ hasText, queryHook, placeholder, isModal }: StyledSearchBoxProps) => {
    return (
        <StyledSearchBox
            loadingIconComponent={() => <Spinner size={30} />}
            $hasText={hasText}
            queryHook={queryHook}
            placeholder={placeholder}
            autoFocus={!!isModal}
        />
    )
}

const StyledSearchBox = styled(AlgoliaSearchBox)<{ $hasText: StyledSearchBoxProps['hasText'] }>`
    width: 100%;

    form {
        /* Styles for form container area of search box */
        width: 100%;
        display: flex;
    }

    input {
        /* Styles for input area of search box */
        flex: 1;
        padding: 0.25rem;
        padding-left: 1.5rem;
        box-shadow: none;
        border: 1px solid;
        border-color: ${palette.neutral[200]};
        border-radius: ${radii.rounded};
        color: ${palette.neutral[700]};
        background-color: ${palette.neutral[0]};
        min-height: 3rem;

        /* Hacky css to make it look like the "reset" cross 
         button is within the input box */
        ${({ $hasText }) =>
            $hasText &&
            css`
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
                border-right: none;
            `}

        &:focus,
        &:active {
            outline: none;
            z-index: ${zIndex.layer02};
            box-shadow: none;
        }
        &::placeholder {
            color: ${palette.neutral[400]};
            font-weight: ${fonts.weight.medium};
        }
    }

    /* clears the internal ‘X’ from Internet Explorer */
    input[type='search']::-ms-clear {
        display: none;
        width: 0;
        height: 0;
    }
    input[type='search']::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
    }
    /* clears the internal ‘X’ from Chrome */
    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
        display: none;
    }

    .ais-SearchBox-submit {
        /* Styles for search/submit button */
        display: none;
    }

    .ais-SearchBox-reset {
        /* Styles for reset button */
        cursor: pointer;
        padding-right: 1.5rem;
        box-shadow: none;
        background-color: ${palette.neutral[0]};
        border: 1px solid ${palette.neutral[200]};
        border-radius: ${radii.rounded};

        /* Hacky css to make it look like this button 
         is within the input */
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-left: none;
    }

    .ais-SearchBox-loadingIndicator {
        /* Styles for loading indicator */
        padding-right: 1rem;
        padding-top: 0.5rem;
        box-shadow: none;
        background-color: ${palette.neutral[0]};
        border: 1px solid ${palette.neutral[200]};
        border-radius: ${radii.rounded};

        /* Hacky css to make it look like this spinner 
         is within the input */
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-left: none;
    }

    // Needed for Safari to make sure reset button lines up
    * {
        -webkit-margin-before: 0;
        -webkit-margin-end: 0;
        -webkit-margin-after: 0;
        -webkit-margin-start: 0;

        -webkit-padding-before: 0;
        -webkit-padding-end: 0;
        -webkit-padding-after: 0;
        -webkit-padding-start: 0;
    }
`
export default SearchBox
