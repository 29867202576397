import { useSearchParams } from 'next/navigation'

import { getCookies, clearCookie } from 'lib/useCookies'
import useSetCookieAsParam from 'lib/useSetCookieAsParam'

/*

    This hook will store any redirect query params in a cookie,
    then redirect the user back to that URL after login.

    NOT used to redirect after checkout. That logic uses the "ref-page" param.

    Run it directly on a login screen to check for redirect params

    ...Or use the redirect fn post-sign-in to perform the redirect

*/

export const loginRedirectParam = 'redirect-uri'

/**
 * Clears any stored redirect cookies and returns the redirect URI
 *
 * Please note...this requires cookies to be initialised to work, so works best on mount
 */
export const redirectIfLoginParam = (): string | null => {
    const cookies = getCookies()

    const redirectUri = loginRedirectParam in cookies ? cookies[loginRedirectParam] : undefined

    if (redirectUri) {
        clearCookie(loginRedirectParam)
    }

    return redirectUri || null
}

/**
 *
 * A simple hook to check whether the current page has a login redirect param
 */
export const useHasLoginRedirectParam = (): boolean => {
    const searchParams = useSearchParams()
    const redirectUri = searchParams.get(loginRedirectParam)

    return !!redirectUri
}

const useLoginRedirect = () => {
    const cookieConfig = {
        queryParam: loginRedirectParam,
        cookieExpirySeconds: 60 * 10, // Expire in 10mins
    }

    useSetCookieAsParam(cookieConfig)
}

export default useLoginRedirect
