import { useEffect } from 'react'

import { useRouter } from 'next/router'

import { setCookie } from 'lib/useCookies'

/*

    useSetCookieAsParam
    Looks for a given query param and sets it as a cookie.
    Quite commonly used for things like:
    - Storing a redirect URL param so we can redirect later
    - Storing a referral code so that we can add it to any payments later


*/

interface Props {
    queryParam: string
    cookieExpirySeconds: number
}

const useSetCookieAsParam = ({ queryParam, cookieExpirySeconds }: Props) => {
    const router = useRouter()
    const { query: params = {} } = router

    useEffect(() => {
        Object.entries(params).forEach(([key, val]) => {
            if (key === queryParam && typeof val === 'string') {
                const cookieKeyName = queryParam

                const options = {
                    maxAge: cookieExpirySeconds,
                    path: '/',
                }

                setCookie(cookieKeyName, decodeURIComponent(val), options)
            }
        })
    }, [])
}

export default useSetCookieAsParam
