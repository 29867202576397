import styled from 'styled-components'

import Box from 'components/Primitives/Box'
import Tag from 'components/Tag'
import MaxWidthContainer from 'containers/MaxWidthContainer'
import { TagPlacementEnum } from 'lib/analyticsApi'
import { fonts } from 'theme'

const tags = [
    { name: 'UK', slug: 'uk' },
    { name: 'US', slug: 'us' },
    { name: 'Asia', slug: 'asia' },
    { name: 'Stocks', slug: 'stocks' },
    { name: 'Inflation', slug: 'inflation' },
    { name: 'Tech', slug: 'tech' },
    { name: 'AI', slug: 'ai' },
    { name: 'ETFs', slug: 'etfs' },
    { name: 'Portfolio', slug: 'portfolio' },
]

export const SUB_NAV_BAR_HEIGHT = 40

const SubNavBar = () => {
    return (
        <Box backgroundColor="neutral.50">
            <MaxWidthContainer centraliseChildren>
                <Tags>
                    {tags.map(tag => (
                        <Tag
                            key={`subnavbar-link-tag-${tag.slug}`}
                            tag={tag}
                            fontSize={fonts.size.XS}
                            trackingParams={{ placement: TagPlacementEnum.SUB_NAVIGATION }}
                        ></Tag>
                    ))}
                </Tags>
            </MaxWidthContainer>
        </Box>
    )
}

const Tags = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: ${SUB_NAV_BAR_HEIGHT}px;
    gap: 3rem;
    padding-left: 3rem;
`

export default SubNavBar
