import type { ReactElement } from 'react'

import styled from 'styled-components'

const MAX_WIDTH = 1400

const MaxWidthContainer = ({
    children,
    centraliseChildren,
    overrideWidth,
}: {
    children: ReactElement | ReactElement[]
    centraliseChildren?: boolean
    overrideWidth?: number
}) => {
    return (
        <Container $centraliseChildren={centraliseChildren}>
            <MaxWidth $overrideWidth={overrideWidth}>{children}</MaxWidth>
        </Container>
    )
}

// Be careful what you change here as it may interfere with other sticky components
const Container = styled.div<{ $centraliseChildren?: boolean }>`
    width: 100%;
    height: inherit;
    display: flex;

    justify-items: ${({ $centraliseChildren }) => (!!$centraliseChildren ? 'center' : 'unset')};
    justify-content: ${({ $centraliseChildren }) => (!!$centraliseChildren ? 'center' : 'unset')};
`

const MaxWidth = styled.div<{ $overrideWidth?: number }>`
    width: 100%;
    max-width: ${({ $overrideWidth }) =>
        $overrideWidth ? `${$overrideWidth}px` : `${MAX_WIDTH}px`};

    @media (max-width: ${({ theme }) => theme.layout.breakpoints.xxLarge}) {
        max-width: ${({ $overrideWidth }) => ($overrideWidth ? `${$overrideWidth}px` : `100vw`)};
    }
`

export default MaxWidthContainer
